@font-face {
  font-family: "FarKetab";
  src: local("Far_Ketab"), url(../Far_Ketab.ttf) format("truetype");
}

@font-face {
  font-family: "MyFont";
  src: local("MyFont"), url(../MyFont.ttf) format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@500;600;700&display=swap");

.menu {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;

  /* padding: 22px; */
  /* padding-top: 0px; */
}

.header {
  display: flex;
  width: 100%;
  /* padding: 22px; */
  padding-top: 0px;
}

.backBtnContainer {
  display: flex;
  align-items: center;
  margin-left: 22px;
  /* justify-content: center; */
}

.backBtn {
  /* width: 90px;
  height: 33px; */
  flex-shrink: 0;
  /* background: #54413b; */
  /* justify-self: flex-start; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #c7b494;
  color: #54413b;
  border: 1.5px solid #D3B67D;
  text-align: center;
  font-family: "FarKetab", sans-serif;
  font-size: 28px;
  font-weight: 400;
  padding: 0px 14px;
  padding-top: 6px;
  text-decoration: none;
  background-color: #FAF4E5;
  border-radius: 40px;


}

.backBtn:hover {
  background-color: #54413b;
}

.backBtn a {
  text-decoration: none;
  color: #54413b;

}

.menuLogo img {
  width: 63px;
  justify-self: center;
  align-self: center;
  margin-right: 22px;
  /* position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0; */
}

.backBtnContainer,
.empty {
  flex: 1;
}

.sections {
  /* display: flex; */
  gap: 8px;
  /* width: 100%; */
  /* overflow-x: scroll; */
  /* background-color: #333; */
  /* overflow: hidden; */
  /* white-space: nowrap; */
}

.section {
  height: 39px;
  border-radius: 25%/100%;
  border: 1.2px solid #D3B67D;
  background: rgba(255, 242, 206, 0);
  padding: 2px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 17px;
  font-family: "FarKetab", sans-serif;
  margin-right: 20px;
  margin-top: 10px;
  /* background-color: red; */
  margin-bottom: 8px;
}

.icon img {
  height: 60px;
}

.itemsTitle {
  font-size: 35px;
  color: #54413b;
  white-space: nowrap;
}

.line {
  height: 2px;
  width: 100%;
  background-color: #D3B67D;
}

.itemsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
  /* background-color: yellow; */
}

.items {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 22px;
  height: 214px;
  width: 100%;
  align-self: center;
  /* background-color: red; */
  /* padding: 40px 40px; */
}

.description {
  height: 100%;
  /* width: 100%; */
  border: 2px solid #D3B67D;
  border-right: 0px;
  background: rgba(255, 255, 255, 0.6);
  font-family: "FarKetab", sans-serif;
  /* flex-grow: 3; */
  width: 40%;
  display: inline;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  /* font-size: 24px; */
}

.itemTitleFa {
  font-size: 30px;
  /* background-color: red; */
  text-align: center;
  color: #000;
  text-align: center;
  font-family: "FarKetab";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px 10px;
}

.itemTitleEn {
  /* font-family: "Noto Sans Arabic", sans-serif; */
  color: #000;
  text-align: center;
  font-family: "Judson", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 10px;
}

.ingredients {
  font-size: 18px;
  /* background-color: red; */
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  font-family: "FarKetab";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px 10px;
  direction: rtl;
  margin-top: 12px;
}

.itemLine {
  height: 2px;
  width: 100%;
  background-color: #D3B67D;
  margin-top: 15px;
  margin-bottom: 15px;
}

.items img {
  height: 214px;
  border: 2px solid #D3B67D;
  /* flex-grow: 2; */
  object-fit: cover;
  margin-right: 20px;
  width: 50%;
  background-color: #FAF4E5;

  /* width: 50%; */
}

.price {
  color: #000;
  text-align: center;
  font-family: "MyFont", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.price p {
  margin: 0px;
  font-size: 15px;
}

.menuWatermark {
  z-index: 300;
  /* background-color: #fff; */
  text-align: center;
  font-size: 10px;
  color: #171717;
  opacity: 0.6;
  margin-top: 15px;
  margin-bottom: 15px;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
}

.menuWatermark a {
  /* text-decoration: none; */
  font-size: 12px;
  color: #171717;
  margin-left: 3px;
}

.comingSoon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 65vh;
  /* background-color: red; */
}

.comingSoon img {
  width: 200px;
}

.goUp {
  position: fixed;
  bottom: 20px;
  right: 25px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  opacity: 0.7;
}

.cafeItemsContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.bold {
  font-weight: 600;
}