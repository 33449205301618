@import url("https://fonts.googleapis.com/css2?family=Judson:wght@400;700&display=swap");

body {
  width: 100vw;
  padding: 0px;
  margin: 0px;
  background-color: #FAF4E5;
  font-family: "FarKetab", sans-serif;
  overflow: hidden;
  overflow-x: hidden;
}

.bg {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  /* left: 0; */
  right: 0;
  top: 0;
  text-align: center;
  /* background-image: url(./KhanehAdinehPattern.webp); */
  /* z-index: 150; */
  /* background-size: cover; <------ */
  /* background-repeat: no-repeat; */
  /* background-position: center top; */
  max-width: 500px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  /* opacity: 0.3; */
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  /* overflow: hidden; */
  /* max-width: 500px; */
}

body {
  margin: 0;
  font-family: "Inter", "Noto Sans Arabic", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  margin: 0;
  color: #121212;
  max-width: 500px;
}

body::after {
  /* background-color: white; */
  background-image: url(./KhanehAdinehPattern3.webp);
  opacity: 0.03;
  /* max-width: 500px; */
  /* background-size: 350px; */
  background-size: cover;
  content: "";
  height: 100vh;
  left: 0;
  /* pointer-events: none; */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
  max-width: 500px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mainContainer {
  /* position: relative; */
}

.pattern {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* left: 0; */
  /* right: 0; */
  /* top: 0; */
  /* background-color: red; */
  /* height: 100vh; */
  /* width: 100%;
  height: 100%;
  background-image: url(./CROPLINE.png);
  background-size: 350px;
  background-size: cover; */
  z-index: 400;
}

.fwPattern {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0;
  top: 3vh;
  width: 80%;
  height: 100vh;
  max-width: 300px;
  min-height: 700px;
  min-width: 236px;
  /* height: 90%; */
}

.logo {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 1%;
  /* width: 42%; */
  /* width: 160px; */
  height: 40%;

  z-index: 201;
}

@media screen and (min-height: 660px) {
  .logo {
    height: unset;
    width: 160px;
  }
}

.button {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 455px;
  z-index: 202;
}

@media screen and (max-height: 700px) {
  .button {
    top: 65vh;
  }
}

@media screen and (max-height: 765px) {
  .button {
    top: 55vh;
  }
}

@media screen and (min-height: 765px) {
  .button {
    top: 60vh;
  }
}

.button img {
  width: 220px;
}

.watermark {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  /* top: auto; */
  bottom: 10px;
  z-index: 300;
  /* background-color: #fff; */
  text-align: center;
  font-size: 10px;
  color: #171717;
  opacity: 0.6;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
}

.watermark a {
  /* text-decoration: none; */
  font-size: 12px;
  color: #171717;
  margin-left: 3px;
}

.bottomSpace {
  height: 250px;
  width: 100%;
}